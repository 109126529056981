import momemt from 'moment';

jQuery( document ).ready(function($) {

  // Form
  $("#yungmanny-nl-form").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $(".form-success").show()
        $(".form-error, #yungmanny-nl-form").hide()
      },
      error: function (err) {
        $(".form-error").show()
      }
    });
  });

  $("#home-yungmanny-nl-form").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $(".home-form-success").show()
        $(".home-form-error, #home-yungmanny-nl-form").hide()
      },
      error: function (err) {
        $(".home-form-error").show()
      }
    });
  });

  // videos Popup
  $('.video').click(function(e){
    e.preventDefault()
    const thisID = $(this).attr( 'data-yt-id' )
    $('.video-popup').addClass('active')
		$( '.featured-video' ).attr( 'src', 'https://www.youtube.com/embed/' + thisID)
  })

  $('.close').click(function(e){
    e.preventDefault()
    $('.video-popup').removeClass('active')
    $( '.featured-video' ).attr( 'src', '')
  })

  // Tour events
  $.ajax( {
		url: 'https://rest.bandsintown.com/artists/Adam%20Doleac/events?app_id=45PRESS_ADAM_DOLEAC',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					// console.log( event );
					let location = event.venue.city + ', ' + event.venue.region;

					if ( location === ', ' ) {
						location = '';
					}

					if ( event.venue.name.toLowerCase() === 'streaming live' ) {
						location = 'Online';
					}

					html += '<div class="event">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn btn-border">' + offer.type + '</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

  // Sliders
  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      }
    ]
  });

  $('.slider-videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    adaptiveHeight: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>'
  });

  // switch
  $('.switch').click(function(e){
    e.preventDefault()
    $('.splash').addClass('off')
    $('.home').removeClass('off')
  })

});